import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB v3 filebased Configuration for your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/",
  "dateChanged": "2022-08-16",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version 3 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB v3 filebased Configuration for your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-16' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version 7 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "openhab-with-your-wqhd-instar-mqttv5-broker-part-ii",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-with-your-wqhd-instar-mqttv5-broker-part-ii",
        "aria-label": "openhab with your wqhd instar mqttv5 broker part ii permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB with your WQHD INSTAR MQTTv5 Broker Part II`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/"
        }}>{`Part I - MQTT Binding Configuration through the Main UI`}</a></li>
      <li parentName="ul">{`Part II - MQTT Binding Configuration through the Configuration Files`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/"
        }}>{`Part III - MQTT Binding Rules`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/"
        }}>{`Part IV - MQTT Binding Scripts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/"
        }}>{`Part V - Groups and Sitemaps`}</a></li>
    </ul>
    <h2 {...{
      "id": "file-based-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#file-based-configuration",
        "aria-label": "file based configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`File-based Configuration`}</h2>
    <p><strong parentName="p">{`A`}</strong>{`: Part 1 of this tutorial installed the OpenHAB MQTT binding and connected it to the internal broker in our INSTAR WQHD camera using the OpenHab 3 `}<strong parentName="p">{`Main UI`}</strong>{`. This `}<strong parentName="p">{`Part II`}</strong>{` will do the same by editing the configuration files of our OpenHab 3 installation.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#file-based-configuration"
        }}>{`File-based Configuration`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#preparation"
            }}>{`Preparation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#run-the-container"
            }}>{`Run the Container`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#binding-installation"
            }}>{`Binding Installation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#configuration"
            }}>{`Configuration`}</a>
            <ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#defining-things"
                }}>{`Defining Things`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#defining-items"
                }}>{`Defining Items`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "#code-definition"
                }}>{`Code Definition`}</a></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#combining-channels"
            }}>{`Combining Channels`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <p>{`Depending on where and how you installed OpenHab you will find those configuration files in different locations. I am following the Docker installation which allows me to define a volume mount for the OpenHab configuration on my hosts file system, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/openhab/openhab_conf`}</code>{`:`}</p>
    <h3 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparation`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`groupadd`}</span>{` -g `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`useradd`}</span>{` -g `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`usermod`}</span>{` -a -G openhab myownuser

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/openhab/`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`openhab_addons,openhab_conf,openhab_userdata`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R `}<span parentName="code" {...{
            "className": "token number"
          }}>{`775`}</span>{` /opt/openhab`}</code></pre></div>
    <h3 {...{
      "id": "run-the-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#run-the-container",
        "aria-label": "run the container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run the Container`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull openhab/openhab:latest-alpine`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --name openhab `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --privileged `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /etc/localtime:/etc/localtime:ro `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/openhab/openhab_addons:/openhab/addons `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/openhab/openhab_conf:/openhab/conf `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/openhab/openhab_userdata:/openhab/userdata `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    openhab/openhab:latest-alpine`}</code></pre></div>
    <p>{`The configuration directory now contains the following files:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` -la /opt/openhab/openhab_conf

total `}<span parentName="code" {...{
            "className": "token number"
          }}>{`56`}</span>{`
drwxr-xr-x `}<span parentName="code" {...{
            "className": "token number"
          }}>{`14`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Aug `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`:21 `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span>{`
drwxrwxr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span>{` root root    `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Aug `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`:19 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Aug `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`:21 automation
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 html
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 icons
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 items
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 persistence
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 rules
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 scripts
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Aug `}<span parentName="code" {...{
            "className": "token number"
          }}>{`11`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`17`}</span>{`:21 services
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 sitemaps
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 sounds
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 things
drwxr-xr-x  `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9001`}</span>{` openhab `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4096`}</span>{` Jun `}<span parentName="code" {...{
            "className": "token number"
          }}>{`27`}</span>{` 07:38 transform`}</code></pre></div>
    <h3 {...{
      "id": "binding-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#binding-installation",
        "aria-label": "binding installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Binding Installation`}</h3>
    <p>{`The Binding configuration can be found in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt/openhab/openhab_conf/addons.cfg`}</code>{`. Add all the bindings and transformations you need here:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# A comma-separated list of bindings to install (e.g. "binding = knx,sonos,zwave")`}</span>{`
binding `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` mqtt,ipcamera

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# A comma-separated list of transformation services to install (e.g. "transformation = jsonpath,map")`}</span>{`
transformation `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` javascript,regex,jsonpath`}</code></pre></div>
    <p>{`Save the file and switch back to the UI. You should see that everything has been installed again.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/67252eabe02b86adcfd52696de47ddbc/34e70/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.304347826086953%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABCklEQVQY01XPu0rFQBSF4byBJIfM/bLnhNkzk+uZ4EHEIopWWlgJgpWNj2Ih4jNLBhs/flj1qmKMOedpmnLOADBFTOgx/EHElNKyLOG/FEJCX2ljMASPIabEOVdad13HGCOEUkpZQSnlBWNsH8GFFIzzah77PqJgRDLCSUspOeUTBsw5r+saCwCQUhpjvPfWWsEFa2nd1NW6PV49PIfzdsw3OixSiHmeY4z9bvCFc0drrXMOEZ114RTTeTjUhwrevtL7D3v5pK/f7f2HM3rb7qZpVkorpbW2WltjrDFQspKp+Xa9fLpuLpoqoe8jdmD8EYwSXIhhHBEDgFNKi2J/WdqvEg5D12Vs6uYX6EQzjIS6J8QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67252eabe02b86adcfd52696de47ddbc/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/67252eabe02b86adcfd52696de47ddbc/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/67252eabe02b86adcfd52696de47ddbc/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/67252eabe02b86adcfd52696de47ddbc/e11fd/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/67252eabe02b86adcfd52696de47ddbc/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/67252eabe02b86adcfd52696de47ddbc/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/67252eabe02b86adcfd52696de47ddbc/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/67252eabe02b86adcfd52696de47ddbc/0528c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67252eabe02b86adcfd52696de47ddbc/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/67252eabe02b86adcfd52696de47ddbc/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/67252eabe02b86adcfd52696de47ddbc/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/67252eabe02b86adcfd52696de47ddbc/34e70/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/67252eabe02b86adcfd52696de47ddbc/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuration",
        "aria-label": "configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuration`}</h3>
    <h4 {...{
      "id": "defining-things",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defining-things",
        "aria-label": "defining things permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defining Things`}</h4>
    <p>{`All thing files are located in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`things`}</code>{` directory, have to have the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.things`}</code>{` file extension and must follow a `}<a parentName="p" {...{
        "href": "https://www.openhab.org/addons/bindings/mqtt.generic/#configuration-via-text-files"
      }}>{`special syntax`}</a>{`. Here we need to add the same configuration as before to connect the MQTT Binding to our camera:`}</p>
    <p><em parentName="p">{`things/instar`}{`_`}{`mqtt.things`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`mqtt:broker:cameraBroker118 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`host`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.118"`}</span>{`,secure`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`false, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1883`}</span>{`, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span>{`, `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`And now we can continue defining a `}<em parentName="p">{`Thing`}</em>{` with all the channels we need. But we can add two new features:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Checking availability`}</strong>{`: We can use our cameras Last-Will-Topic to verify that the camera is available: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`cameras/118/status/testament`}</code>{` can have the payload `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "alive"}`}</code>{` or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val": "dead"}`}</code>{` as configured in `}<a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/"
        }}>{`Part I`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Incoming Value Transformation`}</strong>{`: Our camera uses the JSAN format for MQTT payloads. Which means that we can use the `}<strong parentName="li">{`JSONPath Transformation`}</strong>{` to extract the value from it.`}</li>
    </ul>
    <p>{`Add the following `}<em parentName="p">{`Thing`}</em>{` configuration below the broker configuration to toggle the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Multimedia/Privacy_Mask/"
      }}>{`Privacy Area 1`}</a>{` of your camera that is controlled by `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`multimedia/privacy/region1/enable`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`Thing mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`topic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`home `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Camera 118"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker118`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` availabilityTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/status/testament"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` payloadAvailable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"alive\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` payloadNotAvailable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"dead\\"}"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    Channels`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
    Type `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PrivacyArea1   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` stateTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/status/multimedia/privacy/region1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    commandTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/multimedia/privacy/region1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    transformationPattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"JSONPATH:$.val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    formatBeforePublish`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"%s\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    off`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`After saving the configuration file switch back to the OpenHab UI. You should now see both the configured MQTT Broker and the toggle for the privacy area:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/67252eabe02b86adcfd52696de47ddbc/34e70/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.304347826086953%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABCklEQVQY01XPu0rFQBSF4byBJIfM/bLnhNkzk+uZ4EHEIopWWlgJgpWNj2Ih4jNLBhs/flj1qmKMOedpmnLOADBFTOgx/EHElNKyLOG/FEJCX2ljMASPIabEOVdad13HGCOEUkpZQSnlBWNsH8GFFIzzah77PqJgRDLCSUspOeUTBsw5r+saCwCQUhpjvPfWWsEFa2nd1NW6PV49PIfzdsw3OixSiHmeY4z9bvCFc0drrXMOEZ114RTTeTjUhwrevtL7D3v5pK/f7f2HM3rb7qZpVkorpbW2WltjrDFQspKp+Xa9fLpuLpoqoe8jdmD8EYwSXIhhHBEDgFNKi2J/WdqvEg5D12Vs6uYX6EQzjIS6J8QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67252eabe02b86adcfd52696de47ddbc/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/67252eabe02b86adcfd52696de47ddbc/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/67252eabe02b86adcfd52696de47ddbc/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/67252eabe02b86adcfd52696de47ddbc/e11fd/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/67252eabe02b86adcfd52696de47ddbc/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/67252eabe02b86adcfd52696de47ddbc/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/67252eabe02b86adcfd52696de47ddbc/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/67252eabe02b86adcfd52696de47ddbc/0528c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/67252eabe02b86adcfd52696de47ddbc/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/67252eabe02b86adcfd52696de47ddbc/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/67252eabe02b86adcfd52696de47ddbc/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/67252eabe02b86adcfd52696de47ddbc/34e70/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 1053w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/67252eabe02b86adcfd52696de47ddbc/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can also check the code representation of our `}<em parentName="p">{`Thing`}</em>{` and see that it is basically the same as we had before - but plus the additional functions we configured above:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e1e40802374fa01a7bc4c275d56bafb/1c1a4/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABc0lEQVQoz3XRS2sUQRAA4PkNagxupnuc6cd0V7+nn7O72UhQTxJQEK/+EcGLJ/+07CaRPMhHQVGHooqqjnM+jqMQYhjej/1mxAjjASPc9wgh1Pc9ABhjEDrWw8k03OlijKWU1hqlpEdIcGGM0go4Z+PRNE2EEDKOx8QY44wBZ7e6ix6/enP2+uzt+buNBLmL5eN2t681L8FopZRUSgghH5rvy+76crWSMrzhIxJiTs5/bvtDaiWEkm2rNiXjnNVazbN4ooOff/GPP+fffl98/SV8Wb0/5FZzXIILweRkrVHiBR1cf8ftBq03Q/sC1muAy1wPrdUSg3dKqVm8qLOSiQmLCc8TViAVwFWuh1SKM1KI+ZnHzS5QNlPGKeP6NGcfy6e2/xCWGlxYdFy0d8Ya4+wxFMCDtQEopdNEKCH6dNldzFeltJS2Ja81bddcS4zRx8Wn6IO3Usq7Zu+D1poQQinVCqSUwRhrQEmA+5eIx/v/n/wP8B5k/f1ztboAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e1e40802374fa01a7bc4c275d56bafb/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 230w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 460w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 920w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/49983/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 1046w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e1e40802374fa01a7bc4c275d56bafb/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 230w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 460w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 920w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/8c521/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 1046w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e1e40802374fa01a7bc4c275d56bafb/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 230w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 460w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 920w", "/en/static/9e1e40802374fa01a7bc4c275d56bafb/1c1a4/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 1046w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e1e40802374fa01a7bc4c275d56bafb/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/*
     ### API Explorer
     ![OpenHAB with your WQHD INSTAR MQTTv5 Broker](./OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_0a.png)
     ![OpenHAB with your WQHD INSTAR MQTTv5 Broker](./OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_0b.png)
     */}
    <h4 {...{
      "id": "defining-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#defining-items",
        "aria-label": "defining items permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Defining Items`}</h4>
    <p>{`We now have the data point or channel but we still need a way to interact with it. This can be done with an `}<strong parentName="p">{`Item`}</strong>{`. Enter the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`items`}</code>{` directory in the OpenHab configuration folder and create a file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt.items`}</code>{`. In this file we can now create an item that can interact with the `}<strong parentName="p">{`Channel`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PrivacyArea`}</code>{` of `}<em parentName="p">{`Type switch`}</em>{` we created above: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Privacy Areas */`}</span>{`
Switch    PrivacyArea1   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Privacy Area 1"`}</span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`channel`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt:topic:home:PrivacyArea1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`You can find the channel ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt:topic:home:PrivacyArea`}</code>{` in the `}<strong parentName="p">{`Channel`}</strong>{` configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/20751/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAABSElEQVQY003Qz07jMBAG8LxBoXbimXEm/u+kaaFpqVqQEPAKXListEeegztC2sM+8MqJKu1P32V8mW9cMfM4jiGE3XZrrTUELQAg6hkROeemaQohEJH+j2dddV13PB6naTqdTh2zkHI6HIwxUsq6aKSshRDXcX6aCSGrEFNnjJB13ahGARGdz5fpcMi5TzH1iC0tFUqLAqkUQ2qapnp6vLy9vuz39zH4GLyU0jvHzKvV6qZYzbm5vVoLgYgAsF6vK/v7z+7zL//6qT++1fuXas24GVLKtnBLmDtEWkKkrXXeByJdxfvL8PBstyd3dzabo1KQck4phxC8967w1tq24Dmt1hoRS+0hxf1ujM5EZ7xhpVQoYtvychsA1k2jrmCGiEqpKud+HLchxhCj8x4ANptxGIa+H3Luc84xxmUnc6d1C4Cq/GtZ/g+JEjuly1XovAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 230w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 460w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 920w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/d0853/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 1037w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 230w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 460w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 920w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/7f429/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 1037w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 230w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 460w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 920w", "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/20751/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 1037w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f9068c6fbdb219a2c30ea77b9aabdfc7/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Once you saved the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`items`}</code>{` file the `}<strong parentName="p">{`Item`}</strong>{` should appear in the OpenHab UI. Toggling the state of the switch allows you to turn the Privacy Area 1 on and off:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/7a1fb/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABoUlEQVQoz3WS2WrbQBiF9QCl1NLsnn2RZHu0eIMmbUIh0AuXEtxg0zxDH8AXgZY+uYssrCR1e/gu5j8/A4czk3jvq6oqy7Kua61U0NIa3csYwzlfLJeHw6FpGiGEMeed1sHoxFpbndS2rRCCSzmsrbUAgN1udzweN5sNxthaO2yV1kmeF1JKACCEiLGxudB6vX58/D5v2z7LSyXXH29ubm9nsfIhdz7QfwkjdGkyxpKq9KsmllZ6yQynaZoCAP8iy8ClmaZpoh5+mv0vfP9Evv0mn38ghKRUQsgezoUxdrfbn8oTgy+EhBAlxaeHeLcX77+qq3u+uAMAYEwpG7MTlDIp5Xa75Vyws9kDAExKp2a59ZIFzTQnEEKtJMjSd6N0dCIbjfDbN+l5HOhiT6azWNUhL3zIlTaEUFtEqhwlXSk94zEfzj2MjbvY8/litVrGGJ1zWmuMsQkToR3Iutf7nxBCWZYlMcbpdBpCGC43VfxwfeWdx5gQQl9BKYZYTUzzZQUR6n6YtdY5571XSmFCbVlPpjHPC2Psy3qfUUo7izH5A7uuXpjnUiXIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 230w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 460w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 920w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/db5a9/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 230w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 460w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 920w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/a820a/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 230w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 460w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 920w", "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/7a1fb/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 1030w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/31f41dc677a88bf515ccdf9aaf4c78b0/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "code-definition",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#code-definition",
        "aria-label": "code definition permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code Definition`}</h4>
    <p>{`The definition inside our UI are now as follows:`}</p>
    <p><em parentName="p">{`Broker`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`UID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cameraBroker118
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` MQTT Broker
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`thingTypeUID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`broker
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`configuration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`lwtQos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`publickeypin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`keepAlive`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`60`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`clientid`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 16f924cb`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`0ea1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`44ce`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`a699`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`422a6e5b5469
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`secure`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`birthRetain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`shutdownRetain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`certificatepin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`password`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` instar
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`qos`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`reconnectTime`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`60000`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`port`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1883`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`host`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 192.168.2.118
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`lwtRetain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`username`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` admin
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`enableDiscovery`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span></code></pre></div>
    <p><em parentName="p">{`Camera`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`UID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`topic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`home
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Camera 118
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`thingTypeUID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`topic
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`configuration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payloadNotAvailable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"dead"}'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`availabilityTopic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/118/status/testament
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`payloadAvailable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"alive"}'`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`bridgeUID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`cameraBroker118
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`channels`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` PrivacyArea1
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`channelTypeUID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` mqtt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`switch
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` On/Off Switch
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token null important"
          }}>{`null`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`configuration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`retained`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`postCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`formatBeforePublish`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'{"val":"%s"}'`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`commandTopic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/118/multimedia/privacy/region1/enable
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`stateTopic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` cameras/118/status/multimedia/privacy/region1/enable
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`transformationPattern`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` JSONPATH`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`$.val
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`off`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span></code></pre></div>
    <h3 {...{
      "id": "combining-channels",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#combining-channels",
        "aria-label": "combining channels permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Combining Channels`}</h3>
    <p>{`So far we created one `}<strong parentName="p">{`Channel`}</strong>{` based on a single MQTT topic and bound it to a `}<strong parentName="p">{`Switch-Item`}</strong>{` that could update the topic and toggle one of our camera's functions. Now we want to combine several MQTT topics and update all of them with a single trigger.`}</p>
    <p>{`To do this we first need to define each channel in our `}<strong parentName="p">{`Thing`}</strong>{` configuration file. As an example I want to add all 4 `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Multimedia/Privacy_Mask/"
      }}>{`Privacy Areas`}</a>{` for now:`}</p>
    <p><em parentName="p">{`things/instar`}{`_`}{`mqtt.things`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker118 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` host`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"192.168.2.118"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`secure`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` port`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1883`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` password`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"instar"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

Thing mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`topic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`home `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Camera 118"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`mqtt`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`broker`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`cameraBroker118`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` availabilityTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/status/testament"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` payloadAvailable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"alive\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` payloadNotAvailable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"dead\\"}"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    Channels`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{`
    Type `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PrivacyArea1   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` stateTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/status/multimedia/privacy/region1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    commandTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/multimedia/privacy/region1/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    transformationPattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"JSONPATH:$.val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    formatBeforePublish`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"%s\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    off`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    Type `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PrivacyArea2   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` stateTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/status/multimedia/privacy/region2/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    commandTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/multimedia/privacy/region2/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    transformationPattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"JSONPATH:$.val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    formatBeforePublish`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"%s\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    off`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    Type `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PrivacyArea3   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` stateTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/status/multimedia/privacy/region3/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    commandTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/multimedia/privacy/region3/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    transformationPattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"JSONPATH:$.val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    formatBeforePublish`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"%s\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    off`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    Type `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`switch`}</span>{`    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` PrivacyArea4   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` stateTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/status/multimedia/privacy/region4/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    commandTopic`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"cameras/118/multimedia/privacy/region4/enable"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    transformationPattern`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"JSONPATH:$.val"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    formatBeforePublish`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"{\\"val\\":\\"%s\\"}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    on`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                                    off`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`With the `}<strong parentName="p">{`Channels`}</strong>{` defined we now can add them all to a single `}<strong parentName="p">{`Item`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Privacy Areas */`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* Switch    PrivacyArea1   "Privacy Area 1"  {channel="mqtt:topic:home:PrivacyArea1"} */`}</span>{`
Switch  PrivacyAreas   `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"All Privacy Areas"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`channel`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"mqtt:topic:home:PrivacyArea1, mqtt:topic:home:PrivacyArea2,mqtt:topic:home:PrivacyArea3,mqtt:topic:home:PrivacyArea4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`After saving this configuration file we will now have a switch that we can use, to either manually or by an automation script, to toggle all privacy areas at once:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/42de8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB7ElEQVQoz32Ra2sTQRSG57sgZjM7s3PZue8lWyezIaS4u+1q7SdLMVJsFPxWf4MfAqL4j+s/kNzamFQfXhjOe+bwwjlAKeW9L4qirmttjBPcaS3VCq21EKKqquVy2XUdY0xrve4oqVShFeCce++rqgohKKUY40ptfxhjCCF93/++v/96dwchNMaoHUIqkOeFc1mSEIQwpVT/jTFmMpksFou27YwxB11w+qo5O+99CDbLXZZTSskRMUJ0zYEPTpycjUcnTjiOLcfDYRQfAONoEEEID+woioD4+MN+/oXn35Obn8n1t5ikIk1TIdPVuxLn6e3toihKxviDKYRECAN3flNdfhLNe9HO09MriAjGmBBG6VaE0A/zubUuSciDSSmPYwRKq3yZO8md5EbQGEIpRAzhYPAiigYboefPhnvlThEoR5Ufj8uysi7TxmKMbfmSSUfoI4xxegTGGIRQT6dT77211hiDEFKuUK6I/wNCCUkghGC0Js9za63WGiF8UlVt02Tr4x+T4ITrtL6eJSQBZodzbjOcZXnXndX1xFon9ta+XT5LpdP11YwyCuwj2+GyHF1cvA1hIqXanGpfjHKVm/bLG8rY4TBGOMuLpmm9H0upnkjm6+R3/0guirLvX4dQG2OfSGZcGBUup4TSP5+geEZQ3AXpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 230w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 460w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 920w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/e8a68/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 230w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 460w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 920w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/de74f/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 230w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 460w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 920w", "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/42de8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bfc4c4a0da7cbd10e2bb7996763fe79e/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This is just one example on how to use the `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`INSTAR MQTTv5 API`}</a>{` to take control over camera through OpenHab. A few examples can be found in our `}<a parentName="p" {...{
        "href": "/en/Software/Home_Automation/OpenHAB/MQTTv5/"
      }}>{`OpenHab Overview`}</a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      